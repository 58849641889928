import styled, { keyframes, css } from "styled-components/macro";

const fadeIn = (opacity) => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: ${opacity};
  }
`;

export const Overlay = styled.div`
  ${({ shouldAnimate = true, opacity = "0.75" }) =>
    shouldAnimate
      ? css`
          animation: ${fadeIn(opacity)} 250ms ease-in-out;
        `
      : ""}
  background: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-neutral-black)"};
  inset: 0;
  opacity: ${({ opacity }) => opacity};
  overflow: auto;
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex >= 0 ? zIndex : 15)};
`;
