import { useWindowSize } from "./useWindowSize";

/**
 * just a simple object that holds the screen sizes
 */
export const SCREEN_SIZES = {
  lg: 1920,
  md: 1440,
  sm: 900,
};

/*
 * @desc: A hook that returns boolean flags for different screen sizes
 * @return: { lg: boolean, md: boolean, sm: boolean }
 * dictating if the screen size is extra large, medium, or small
 */
export const useScreenSize = () => {
  const windowSize = useWindowSize();

  return {
    lg: windowSize.width >= SCREEN_SIZES.lg,
    md:
      windowSize.width >= SCREEN_SIZES.sm + 1 &&
      windowSize.width < SCREEN_SIZES.md,
    sm: windowSize.width <= SCREEN_SIZES.sm,
  };
};
