import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Refiner from "refiner-js";
import styled from "styled-components/macro";
import { useAuthContext } from "context/AuthContext/useAuthContext";
import { FetchProvider } from "context/FetchContext/FetchContext";
import { HelpbarProvider } from "context/HelpbarContext/HelpbarContext";
import { LDStatusProvider } from "context/LDStatusContext/LDStatusContext";
import { WorkspaceAlertProvider } from "context/WorkspaceAlertContext/WorkspaceAlertProvider";
import { ErrorFallback } from "components/Blocks/ErrorBoundary/ErrorFallback";
import { history } from "utils/constants/history";
import { lazyWithRetry } from "utils/helpers/lazyWithRetry";

const AuthedApp = lazyWithRetry(() => import("pages/App/AuthedApp"));
const UnauthedApp = lazyWithRetry(() => import("pages/App/UnauthedApp"));

export const App = () => {
  Refiner("setProject", process.env.REACT_APP_REFINER_PROJECT_ID);
  const { isAuthenticatedByAuth0 } = useAuthContext();

  if (!isAuthenticatedByAuth0) {
    localStorage.setItem("deeplink", window.location.pathname);
  }

  if (process.env.REACT_APP_ENVIRONMENT === "ephemeral") {
    const url = localStorage.getItem("training-client-eph-redirect") || "";
    const searchParams = new URLSearchParams(history.location.search);
    const urlSearchParams = url.includes("?")
      ? new URLSearchParams(url.split("?")[1])
      : [];
    urlSearchParams.forEach((value, key) => searchParams.set(key, value));
    history.push({
      pathname: url.split("?")?.[0] || url,
      search: searchParams.toString(),
    });
    localStorage.removeItem("training-client-eph-redirect");
  }
  return (
    <Suspense fallback={<FallbackScreen />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FetchProvider>
          <LDStatusProvider>
            <HelpbarProvider>
              <WorkspaceAlertProvider>
                {isAuthenticatedByAuth0 ? <AuthedApp /> : <UnauthedApp />}
              </WorkspaceAlertProvider>
            </HelpbarProvider>
          </LDStatusProvider>
        </FetchProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

const FallbackScreen = styled.main`
  background: var(--color-primary-gradient);
  height: 100vh;
  width: 100vw;
`;
