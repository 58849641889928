import { useState, useCallback } from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";

const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState(() =>
    queryString.parse(location.search)
  );
  const updateSearchParams = useCallback(
    (newParams) => {
      const updatedParams = { ...searchParams, ...newParams };
      const newSearch = queryString.stringify(updatedParams);

      history?.push({ search: newSearch });
      setSearchParams(updatedParams);
    },
    [searchParams, history]
  );

  const deleteParams = useCallback(
    (...paramKeys) => {
      if (!paramKeys || paramKeys.length === 0) return;
      const remainingParams = { ...searchParams };
      paramKeys.forEach((key) => {
        delete remainingParams[key];
      });

      const newSearch = queryString.stringify(remainingParams);
      history.push({ search: newSearch });
      setSearchParams(remainingParams);
    },
    [searchParams, history]
  );

  return [searchParams, updateSearchParams, deleteParams];
};

export { useSearchParams };
