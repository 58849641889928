import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";

const baseStyles = css`
  color: var(--color-neutral-23);
  font-family: var(--font-family);
  font-weight: var(--weight-regular);
  margin: 0;
  padding: 0;
`;

const overrideStyles = css`
  color: ${({ color, uiTheme }) =>
    color ? color : uiTheme && `var(--color-${uiTheme}-default)`};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
`;

const variants = {
  paragraph: css`
    font-size: 16px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0em;
    line-height: 24px;
  `,
  subtitle1: css`
    font-size: 22px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0em;
    line-height: 34px;
  `,
  subtitle2: css`
    font-size: 18px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0em;
    line-height: 26px;
  `,
  label: css`
    font-size: 12px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0.02em;
    line-height: 18px;
  `,
  bodySmall: css`
    font-size: 14px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0em;
    line-height: 20px;
  `,
  caption: css`
    font-size: 12px;
    font-weight: ${({ isBold }) => isBold && "var(--weight-bold)"};
    letter-spacing: 0em;
    line-height: 18px;
  `,
};

/**
 * @description A text component that can be used to render different types of text.
 * @param {string} color - The color of the text.
 * @param {boolean} isBold - Whether the text should be bold.
 * @param {string} padding - The padding of the text.
 * @param {string} textAlign - The text alignment.
 * @param {string} uiTheme - The theme of the text.
 * @param {string} variant - The type of text to render.
 * @example
 * <Text variant="subtitle1" color="red" padding="10px" textAlign="center" uiTheme="primary" isBold>
 */
export const Text = styled.p`
  ${baseStyles}
  ${({ variant }) => variants[variant] || variants.paragraph}
  ${overrideStyles}
`;

Text.propTypes = {
  color: PropTypes.string,
  isBold: PropTypes.bool,
  padding: PropTypes.string,
  textAlign: PropTypes.string,
  uiTheme: PropTypes.string,
  variant: PropTypes.oneOf([
    "paragraph",
    "subtitle1",
    "subtitle2",
    "label",
    "bodySmall",
    "caption",
  ]),
};
